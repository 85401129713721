import React from "react";
import { ChakraProvider, theme, Spinner, Center } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import BuyBitcoin from "./pages/BuyBitcoin";
import Profile from "./pages/Profile";
import { Metrics } from "./pages/Metrics";
import Earn from "./pages/Earn";
import Marketplace from "./pages/Marketplace";
import MyBTCPurchases from "./pages/MyBTCPurchases";
import News from "./pages/News";
import Relationships from './pages/Relationships';
import { client } from "./contexts/AuthContext";
import AllUsers from "./pages/AllUsers";
import ProfileDetails from "./pages/ProfileDetails";
import AddProduct from "./pages/AddProduct";
import Resilience from "./pages/Resilience";
import LandPurchaseCanungra from "./pages/LandPurchaseCanungra";
import Wallet from "./pages/Wallet";
import GroupBeefBuy from "./pages/GroupBeefBuy";
import Start from "./pages/Start";
import AllUsersPurchases from "./pages/AllUsersPurchases";
import NodeInfo from "./pages/NodeInfo";
import BTCPayInvoices from "./pages/BTCPayInvoices";
import BTCPayInvoicesWithUserIdPage from "./pages/BTCPayInvoicesWithUserIdPage";
import MysteryMasteryCourse from "./pages/MysteryMasteryCourse";
import SuccessPrinciplesMasteryCourse from "./pages/SuccessPrinciplesMasteryCourse";
import SmallFruitAndVegBox from "./pages/SmallFruitAndVegBox";
import OurLocationHubs from "./pages/OurLocationHubs";
import GoldenHillCommunityButcher from "./pages/GoldenHillCommunityButcher";
import KangarooMeatGroupBuy from "./pages/KangarooMeatGroupBuy";
import AddCampaign from "./pages/AddCampaign";
import EmpoweredCommunities from "./pages/EmpoweredCommunities"
import OrganicChickenGroupBuy from "./pages/OrganicChickenGroupBuy";
import ProducerForageFarms from "./pages/ProducerForageFarms";
import OurProducers from "./pages/OurProducers";
import FarmSalesDashboard from "./pages/FarmSalesDashboard"
import GrouHalfBeefBuy from "./pages/products/GrouHalfBeefBuy";
import OurRekoModel from "./pages/OurRekoModel";
import CultivatingResiliencePage from "./pages/CultivatingResillence";
import CultivatingResilienceInfographic from "./pages/CultivatingResillenceInfographic";
import AboutUs from "./pages/AboutUs";
import TrialKangarooGroupBuy from "./pages/TrialKangarooGroupBuy";
import ConnectionRequestForm from "./pages/ConnectionRequestForm";


const AppContent = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/buybitcoin" element={<BuyBitcoin />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/metrics" element={<Metrics />} />
      <Route path="/earn" element={<Earn />} />
      <Route path="/marketplace" element={<Marketplace />} />
      <Route path="/news" element={<News />} />
      <Route path="/mybtcpurchases" element={<MyBTCPurchases />} />
      <Route path="/relationships" element={<Relationships />} />
      <Route path="/allusers" element={<AllUsers />} />
      <Route path="/profiledetails" element={<ProfileDetails />} />
      <Route path="/addproduct" element={<AddProduct />} />
      <Route path="/resilience" element={<Resilience />} />
      <Route path="/LandPurchaseCanungra" element={<LandPurchaseCanungra />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/groupbeefbuy" element={<GroupBeefBuy />} />
      <Route path="/start" element={<Start />} />
      <Route path="alluserspurchases" element={<AllUsersPurchases />} />
      <Route path="/nodeinfo" element={<NodeInfo />} />
      <Route path="/btcpayinvoices" element={<BTCPayInvoices />} />
      <Route path="/allorders" element={<BTCPayInvoicesWithUserIdPage />} />
      <Route path="/mysterymasterycourse" element={<MysteryMasteryCourse />} />
      <Route path="/success-principles-mastery" element={<SuccessPrinciplesMasteryCourse />} />
      <Route path="/small-fruit-and-veg-box" element={<SmallFruitAndVegBox />} />
      <Route path="/our-location-hubs" element={<OurLocationHubs />} />
      <Route path="/golden-hill-community-butcher" element={<GoldenHillCommunityButcher />} />
      <Route path="/kangaroo-meat-group-buy" element={<KangarooMeatGroupBuy />} />
      <Route path="/campaigns" element={<AddCampaign />} />
      <Route path="/empowered-communities" element={<EmpoweredCommunities />} />
      <Route path="/organic-chicken-group-buy" element={<OrganicChickenGroupBuy />} />
      <Route path="/forage-farms" element={<ProducerForageFarms />} />
      <Route path="/producers" element={<OurProducers />} />
      <Route path="/farm-sales-dashboard" element={<FarmSalesDashboard />} />
      <Route path="/products/group-half-beef-buy" element={<GrouHalfBeefBuy />} />
      <Route path="/our-reko-model" element={<OurRekoModel />} />
      <Route path="/cultivating-resilience-circular" element={<CultivatingResiliencePage />} />
      <Route path="/cultivating-resilience-infographic" element={<CultivatingResilienceInfographic />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/kangaroo" element={<TrialKangarooGroupBuy />} />
      <Route path="/connectionrequestform" element={<ConnectionRequestForm />} />




    </Routes>
  );
};
export const App = () => (
  <Router>
    <ApolloProvider client={client}>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <AppContent />
        </ChakraProvider>
      </AuthProvider>
    </ApolloProvider>
  </Router>
);